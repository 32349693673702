import { AsyncPipe, NgClass } from '@angular/common';
import { Component, OnInit, input, output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { StreakBloc } from '@kp/streaks/streak.bloc';
import { MockComponent } from '@kutil/test';
import { SvgIconComponent } from 'angular-svg-icon';

const DEFAULT_CLOSE_TIME = 3000;

@Component({
  selector: 'kp-modal-purposeful-day',
  standalone: true,
  templateUrl: './modal-purposeful-day.component.html',
  styleUrls: ['./modal-purposeful-day.component.scss'],
  imports: [SvgIconComponent, AsyncPipe, RouterLink, TranslocoDirective, NgClass],
})
export class ModalPurposefulDayComponent implements OnInit {
  constructor(public streakBloc: StreakBloc) {}

  closeOnTimer = input<boolean>(false);
  closeTime = input<number>(DEFAULT_CLOSE_TIME);

  closeClicked = output<void>();

  ngOnInit() {
    this.streakBloc.refreshStreaks();
  }

  onAnimationStart() {
    if (this.closeOnTimer() !== false) {
      setTimeout(() => this.closeClicked.emit(), this.closeTime());
    }
  }
}

export const MockModalPurposefulDayComponent = MockComponent({
  selector: 'kp-modal-purposeful-day',
  inputs: ['closeOnTimer', 'closeTime'],
});

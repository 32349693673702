import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { LegalDocumentsBloc } from '@kbloc';
import { EnvironmentVariablesService } from '@kenv';
import { AuthenticationBloc } from '@kp/auth/authentication.bloc';
import { DataLinkBloc } from '@kp/data-link/data-link.bloc';
import { PulseSurveyData } from '@kp/data-link/data-link.model';
import { UserBloc } from '@kp/user/user.bloc';
import { DataStoreService } from '@kservice';
import { Product, UserType } from '@ktypes/enums';
import { DataStatus, LegalDocumentItem, LegalDocumentType, Status } from '@ktypes/models';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, map, skipWhile, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LegalDocumentGuard {
  constructor(
    private _authenticationBloc: AuthenticationBloc,
    private _dataLinkBloc: DataLinkBloc,
    private _dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService,
    private _legalDocumentsBloc: LegalDocumentsBloc,
    private _router: Router,
    private _userBloc: UserBloc
  ) {}

  canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot /*, state: RouterStateSnapshot*/
  ): Observable<boolean | UrlTree> | boolean {
    if (DataLinkBloc.isExternalPulseSurvey && activatedRouteSnapshot.url?.[0]?.path === 'pulse_survey') {
      return true;
    }

    return combineLatest([this._userBloc.fetchingUser$, this._dataStoreService.user$]).pipe(
      filter(
        ([fetchingUser, user]) => !fetchingUser?.data && ((!user || user?.hasLoadedData || user?.hasNoData) as boolean)
      ),
      switchMap(([_, user]) => {
        // always ensure legal document guard starts fresh
        this._legalDocumentsBloc.clearLegalDocumentStatus();
        this._legalDocumentsBloc.clearLegalDocumentStorage();

        const dataLinkUserType = ((this._dataLinkBloc.currentDataLink?.data ?? {}) as PulseSurveyData)?.userType;

        if (
          !DataLinkBloc.isExternalPulseSurvey &&
          this._environmentVariablesService.product === Product.resourceful &&
          !this._isLoggedInResourcefulUser()
        ) {
          // Don't check legal docs for Resourceful if not external pulse survey and not logged in
          return of(true);
        }

        if (
          !this._legalDocumentsBloc.currentLegalDocumentStatus?.data ||
          ![Status.done, Status.error].includes(this._legalDocumentsBloc.currentLegalDocumentStatus?.status)
        ) {
          this._legalDocumentsBloc.getCurrentLegalDocumentStatus();
        }

        return this._legalDocumentsBloc.currentLegalDocumentStatus$.pipe(
          map((docStatus: DataStatus<LegalDocumentItem[]>) => {
            const legalDocumentItems = docStatus?.data;
            if (user && Array.isArray(legalDocumentItems)) {
              return legalDocumentItems.filter((document) => {
                if (DataLinkBloc.isExternalPulseSurvey && dataLinkUserType !== UserType.user) {
                  return document.type === LegalDocumentType.PRIVACY && !document.signed;
                }
                return !document.signed;
              });
            } else if (docStatus?.status === Status.error) {
              console.warn('guard errored trying to retrieve legal doc status', docStatus);
              return this._router.parseUrl(this._authenticationBloc.isLoggedIn() ? '/error' : '/welcome/error');
            }
            return null;
          }),
          skipWhile((docItemsCheckResult: LegalDocumentItem[] | UrlTree) => docItemsCheckResult == null),
          map((docItemsCheckResult: LegalDocumentItem[] | UrlTree) => {
            if (docItemsCheckResult instanceof UrlTree || typeof docItemsCheckResult === 'boolean') {
              return docItemsCheckResult;
            }

            if (
              docItemsCheckResult.length > 0 &&
              (!DataLinkBloc.isExternalPulseSurvey || (dataLinkUserType != null && dataLinkUserType !== UserType.user))
            ) {
              return this._router.parseUrl(this.getPrivacyUrl(user?.type));
            }

            if (docItemsCheckResult.length > 0) {
              // likely only gets here if dataLinkUserType is user but somehow there is a legal doc to sign
              console.warn(
                'guard error: unsigned legal docs but not caught',
                docItemsCheckResult,
                DataLinkBloc.isExternalPulseSurvey,
                dataLinkUserType
              );
              // TODO: Should this be a privacy page? If so, does it get the user back on track once done?
              //  Or should it continue to allow the user through without accepting as it was?
              return this._router.parseUrl(this._authenticationBloc.isLoggedIn() ? '/error' : '/welcome/error');
            }
            return true;
          })
        );
      })
    );
  }

  getPrivacyUrl(userType?: UserType): string {
    return DataLinkBloc.isExternalPulseSurvey || userType === UserType.pulse ? '/privacy-pledge' : '/privacy-full';
  }

  private _isLoggedInResourcefulUser(): boolean {
    return this._environmentVariablesService.product === Product.resourceful && this._authenticationBloc.isLoggedIn();
  }
}

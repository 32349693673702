/* eslint-disable */
// NOTE :: DO NOT EDIT :: this file is automatically generated by generate-environment-variables.js node script

export const envVariables: { [key: string]: string } = {
  CIRCLE_TAG: 'prod-purposeful-v123',
  PROJECT: 'purposeful',
  PRODUCT: 'purposeful',
};
export const latestTag: string = 'prod-purposeful-v123';
export const latestVersion: string = 'v123';
export const versions: { app: { [key: string]: string }, branch: string, npm_version: string, revision: string } = {
  app: {
    purposeful: '7.1.0',
    insightful: '3.1.0',
  },
  branch: 'HEAD',
  npm_version: '166.0.0',
  revision: 'eebd14988',
};
/* eslint-enable */

import { Injectable } from '@angular/core';
import { DailyChallengeStatusApi } from '@kp/purposeful-day/daily-challenge-status.api';
import { PurposefulDay } from '@kp/purposeful-day/purposeful-day.model';
import { DataStatus, Status } from '@ktypes/models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DailyChallengeStatusBloc {
  // NOTE: While most references are now changed to Purposeful Day, leaving Bloc Class name as
  //       Daily Challenge because that is how it is named in the API/Swagger
  constructor(private _dailyChallengeStatusApi: DailyChallengeStatusApi) {}

  private _purposefulDayStatusSubject = new BehaviorSubject<DataStatus<PurposefulDay>>(
    new DataStatus<PurposefulDay>(Status.starting)
  );

  private _shownPurposefulDayCelebration$ = new BehaviorSubject<boolean>(null);

  private _showLoadingState = true;

  get purposefulDayStatus$(): Observable<DataStatus<PurposefulDay>> {
    return this._purposefulDayStatusSubject.asObservable();
  }

  get shownPurposefulDayCelebration$(): Observable<boolean> {
    return this._shownPurposefulDayCelebration$.asObservable();
  }

  setPurposefulDayCelebrationStatus(value: boolean) {
    this._shownPurposefulDayCelebration$.next(value);
  }

  refreshPurposefulDayStatus(): void {
    if (this._showLoadingState) {
      this._purposefulDayStatusSubject.next(new DataStatus<PurposefulDay>(Status.starting));
      this._showLoadingState = false;
    }
    this._dailyChallengeStatusApi.getCurrentStatus().then((purposefulDayStatus) => {
      if (purposefulDayStatus) {
        const status = new DataStatus<PurposefulDay>(Status.done, purposefulDayStatus.data?.[0]);
        this._purposefulDayStatusSubject.next(status);
      }
    });
  }
}

import { computed, Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ThemeApi } from '@kapi';
import { DataStatus, Status, Theme } from '@ktypes/models';

@Injectable({
  providedIn: 'root',
})
export class ThemeBloc {
  constructor(private _themeApi: ThemeApi) {}

  private _themesStatus = signal<DataStatus<Theme[]>>(null);
  themesStatus = computed<DataStatus<Theme[]>>(() => this._themesStatus());
  themesStatus$ = toObservable(this.themesStatus);

  getThemes() {
    this._themesStatus.set(new DataStatus<Theme[]>(Status.starting, []));
    this._themeApi.getThemes().then((themes) => {
      this._themesStatus.set(new DataStatus<Theme[]>(themes ? Status.done : Status.error, themes));
    });
  }
}

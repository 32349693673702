import { Component, Input } from '@angular/core';
import { productDisplayName } from '@kservice';
import { Product } from '@ktypes/enums';
import { MockComponent } from '@kutil/test';
import { format } from 'date-fns/format';

@Component({
  selector: 'kp-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss'],
})
export class CopyrightComponent {
  @Input() includeProMedica = false;
  @Input() includeKumanuTrademark = false;
  @Input() product: Product;

  productDisplayName = productDisplayName;

  year: string = format(new Date(), 'yyyy');
}

export const MockCopyrightComponent = MockComponent({
  selector: 'kp-copyright',
  inputs: ['includeProMedica', 'includeKumanuTrademark', 'product'],
});

import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { GlobalElementsService } from '@kp/shared/services/global-elements.service';
import { MockComponent } from '@kutil/test';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'kp-bottom-arrow-nav',
  templateUrl: './bottom-arrow-nav.component.html',
  styleUrls: ['./bottom-arrow-nav.component.scss'],
})
export class BottomArrowNavComponent implements OnInit, OnDestroy {
  constructor(
    private _location: Location,
    private _globalElementsService: GlobalElementsService
  ) {}

  @Input() backAriaLabel = 'back';
  @Input() disableBackButton = false;
  @Input() disableNextButton = false;
  @Input() nextAriaLabel = 'continue';
  @Input() showsBackButton = true;
  @Input() showsContent = false;
  @Input() showFade = true;
  @Input() showLargeFade = true;
  @Input() showsNextButton = true;
  @Input() showsSkip = false;
  @Input() skipAriaLabel = 'skip';
  @Input() skipText = 'Skip';

  @Output() backClick = new EventEmitter<void>();
  @Output() nextClick = new EventEmitter<void>();

  showChromeNav = true;
  showHeaderNav = true;
  showNav = this.showHeaderNav || this.showChromeNav;

  private _destroy$ = new Subject<void>();

  ngOnInit() {
    combineLatest([this._globalElementsService.showHeader$, this._globalElementsService.showChrome$])
      .pipe(takeUntil(this._destroy$))
      .subscribe(([showHeader, showChrome]) => {
        this.showHeaderNav = showHeader ?? this.showHeaderNav;
        this.showChromeNav = showChrome ?? this.showChromeNav;
        this.showNav = this.showHeaderNav || this.showChromeNav;
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  back() {
    if (this.disableBackButton) {
      return;
    }
    this.backClick.observed ? this.backClick.emit() : this._location.back();
  }

  next() {
    if (this.disableNextButton) {
      return;
    }
    this.nextClick.emit();
  }
}

export const MockBottomArrowNavComponent = MockComponent({
  selector: 'kp-bottom-arrow-nav',
  inputs: [
    'backAriaLabel',
    'disableBackButton',
    'disableNextButton',
    'nextAriaLabel',
    'showsBackButton',
    'showsContent',
    'showFade',
    'showLargeFade',
    'showsNextButton',
    'showsSkip',
    'skipAriaLabel',
    'skipText',
  ],
});

<ng-container *transloco="let t">
  <div class="purposeful-day-modal-container">
    <a class="purposeful-days-link" [routerLink]="['/purposeful-days']" (click)="closeClicked.emit()">
      <svg-icon src="/assets/icons/info-circle.svg"></svg-icon>
    </a>
    <img
      class="wellbeing-tree"
      src="/assets/images/Tree%20of%20Wellbeing.png"
      alt="Tree with deep roots, strong trunk, bushy branches" />
    <h2 class="title">{{ t('Purposeful Day') }}</h2>
    @if ((streakBloc.streak$ | async) ?? { data: { current: -1 } }; as streakStatus) {
      <div class="subtitle" [ngClass]="{ loaded: streakStatus?.data?.current !== -1 }">{{ t('complete') }}</div>
      <div class="streak">
        <svg-icon src="/assets/icons/leaf.svg"></svg-icon>
        <h3
          class="streak-text"
          [ngClass]="{ loaded: streakStatus?.data?.current !== -1 }"
          (animationstart)="onAnimationStart()">
          @if (!streakStatus?.data?.current || streakStatus?.data?.current - 1 < 0) {
            0
          } @else {
            {{ t('currentLength-day streak!', { currentLength: streakStatus?.data?.current }) }}
          }
        </h3>
      </div>
    }
    <button class="button button-pill button-close" (click)="closeClicked.emit()">Got it</button>
  </div>
</ng-container>

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi, RequestType } from '@kapi';
import { EnvironmentVariablesService } from '@kenv';
import { PurposefulDay } from '@kp/purposeful-day/purposeful-day.model';
import { DataStoreService } from '@kservice';
import { HttpStatusCode } from '@ktypes/enums';
import { DataStatus, JsonObject, Status, StatusMessage } from '@ktypes/models';
import { DateTimeUtil } from '@kutil';
import { Observable, firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DailyChallengeStatusApi extends BaseApi {
  // NOTE: While most references are now changed to Purposeful Day, leaving API Class name as
  //       Daily Challenge because that is how it is named in the API/Swagger
  constructor(
    client: HttpClient,
    dataStoreService: DataStoreService,
    environmentVariablesService: EnvironmentVariablesService
  ) {
    super(client, dataStoreService, environmentVariablesService);
  }

  public async getCurrentStatus() {
    const now = new Date();
    const isEvening = DateTimeUtil.isEvening(now);
    if (isEvening) {
      /// DateTimeUtil isEvening work on the range [6pm - 4am] but the
      /// API will return query results based on a "new day" being after
      /// midnight. Subtracts 4 hours to continue querying for the previous
      /// day until 4am.
      now.setHours(now.getHours() - 4);
      return this.getStatusOn(now);
    }

    return await this.getStatusOn(new Date());
  }

  public async getStatusOn(date: Date) {
    return await this.getStatusBetween(date, date);
  }

  public async getStatusSince(from: Date) {
    return await this.getStatusBetween(from, new Date());
  }

  public async getStatusBetween(from: Date, to: Date) {
    return await this._getPurposefulDayStatus(from, to);
  }

  private async _getPurposefulDayStatus(startDate: Date, endDate: Date): Promise<DataStatus<PurposefulDay[]>> {
    const body = {
      minDate: DateTimeUtil.formatDate(startDate),
      maxDate: DateTimeUtil.formatDate(endDate),
    };

    // NOTE: Daily Challenge was renamed Purposeful Day, but API endpoint is still /daily-challenge
    const url = this.buildUrl('/daily-challenge', true, body);

    const request$ = this.performRequest<JsonObject[]>(RequestType.GET, url, { includeToken: true }).pipe(
      map((response: HttpResponse<JsonObject[]>): DataStatus<PurposefulDay[]> => {
        if (response?.ok) {
          const records = response.body.map((value: JsonObject) => new PurposefulDay().deserialize(value));
          return new DataStatus<PurposefulDay[]>(Status.done, new StatusMessage(HttpStatusCode.OK, ''), records);
        }
        return new DataStatus<PurposefulDay[]>(
          Status.error,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          new StatusMessage(
            HttpStatusCode.INTERNAL_SERVER_ERROR,
            'There was an error retrieving your Purposeful Day status'
          ),
          null
        );
      }),
      catchError((error): Observable<DataStatus<PurposefulDay[]>> => {
        console.warn('Failed getting Purposeful Day status: ', error);
        return of(
          new DataStatus<PurposefulDay[]>(
            Status.error,
            new StatusMessage(
              HttpStatusCode.INTERNAL_SERVER_ERROR,
              'There was a server error retrieving your Purposeful Day status'
            ),
            null
          )
        );
      })
    );
    return firstValueFrom(request$).catch((error): null => {
      console.warn('Error getting Purposeful Day status: ', error);
      return null;
    });
  }
}

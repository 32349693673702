import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentVariablesService } from '@kenv';
import { DataStoreService } from '@kservice';
import { RequestType } from '@ktypes/enums';
import { Theme } from '@ktypes/models';
import { firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseApi } from './base.api';

@Injectable({
  providedIn: 'root',
})
export class ThemeApi extends BaseApi {
  constructor(
    client: HttpClient,
    dataStoreService: DataStoreService,
    environmentVariablesService: EnvironmentVariablesService
  ) {
    super(client, dataStoreService, environmentVariablesService);
  }

  async getThemes(): Promise<Theme[]> {
    const url = this.buildUrl('/themes');
    const request$ = this.performRequest<Theme[]>(RequestType.GET, url).pipe(
      map((response: HttpResponse<Theme[]>): Theme[] =>
        response?.body?.map?.((themes) => new Theme().deserialize(themes))
      ),
      catchError((err) => {
        console.warn('Failed getting product themes: ', err);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error): null => {
      console.warn('Error getting product themes: ', error);
      return null;
    });
  }
}

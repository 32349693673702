import { Injectable } from '@angular/core';
import { AnalyticsBloc } from '@kanalytics';
import { UserApi, UserEventApi } from '@kapi';
import { AuthenticationApi, BaseUserBloc } from '@kauth';
import { FirstVisitBloc, LandingDynamicBloc, TagBloc } from '@kbloc';
import { EnvironmentVariablesService } from '@kenv';
import { EligibilityCandidate } from '@kp/eligibility/eligibility-candidate.model';
import { SharingValidation } from '@kp/sharing/sharing-validation';
import { AuthDataService, BrowserStorage, DataStoreService, OnboardingUtilities, ThemeService } from '@kservice';
import { HttpStatusCode, UserType } from '@ktypes/enums';
import {
  AuthData,
  DataStatus,
  Group,
  JsonObject,
  LiveSupportInformation,
  NON_SSO_AUTH_PROVIDER,
  Settings,
  Status,
  StatusMessage,
  User,
} from '@ktypes/models';
import { BehaviorSubject, Observable, Subject, firstValueFrom } from 'rxjs';
import { distinctUntilChanged, map, skipWhile, switchMap, take, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserBloc extends BaseUserBloc {
  constructor(
    private _analyticsBloc: AnalyticsBloc,
    private _authData: AuthDataService,
    private _authApi: AuthenticationApi,
    private _browserStorage: BrowserStorage,
    private _dataStore: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService,
    private _firstVisitBloc: FirstVisitBloc,
    private _landingDynamicBloc: LandingDynamicBloc,
    private _onboardingUtilities: OnboardingUtilities,
    private _tagBloc: TagBloc,
    private _themeService: ThemeService,
    private _userApi: UserApi,
    userEventsApi: UserEventApi
  ) {
    super(_authData, _authApi, _dataStore, _userApi, userEventsApi);
  }

  private _userCreationStatus: Status;

  private _existingUserEmail$ = new BehaviorSubject<string>('');
  private _onboardingPasswordSetRequired$ = new Subject<boolean>();
  private _passwordChanged$ = new BehaviorSubject<DataStatus<boolean>>(null);
  private _registerStatus$ = new BehaviorSubject<DataStatus<boolean>>(null);

  get existingUserEmail$() {
    return this._existingUserEmail$.asObservable();
  }

  get liveSupport$(): Observable<LiveSupportInformation> {
    return this._dataStore.user$.pipe(
      map(
        (user: User) =>
          user?.group?.liveSupport?.[this._environmentVariablesService.product as 'purposeful' | 'resourceful']
      ),
      distinctUntilChanged()
    );
  }

  get registerStatus$() {
    return this._registerStatus$.asObservable();
  }

  get passwordSetRequired$() {
    return this._onboardingPasswordSetRequired$.asObservable();
  }

  get passwordChanged$() {
    return this._passwordChanged$.asObservable();
  }

  getUserEmail(userId?: string, emailKey?: string) {
    const userFetchingDestroy$ = new Subject<void>();
    this._dataStore.user$
      .pipe(
        takeUntil(userFetchingDestroy$),
        skipWhile((user: User) => user == null),
        take(1),
        switchMap((user: User) => {
          const id = userId || user?.id;
          if (this._setEmailIfExists(user, emailKey)) {
            userFetchingDestroy$.next();
            userFetchingDestroy$.complete();
            return this._dataStore.user$;
          } else if (id) {
            this.getUser(id);
            return this.fetchingUser$;
          }
          return this._dataStore.user$;
        }),
        skipWhile((userFetchingStatus: DataStatus<boolean>) => userFetchingStatus?.data),
        take(1),
        switchMap(() => this._dataStore.user$)
      )
      .subscribe((user: User) => {
        if (this._setEmailIfExists(user, emailKey)) {
          userFetchingDestroy$.next();
          userFetchingDestroy$.complete();
        }
      });
  }

  createOnboardingUser(group: Group, userId?: string): void {
    const { groupCode, liveSupport, theme } = group;
    if (![Status.starting, Status.done].includes(this._userCreationStatus)) {
      this._userCreationStatus = Status.starting;
      this._dataStore.setAuthStatus(
        new DataStatus<AuthData>(Status.starting, new StatusMessage(Status.starting, ''), null)
      );
      // Create onboarding user
      this._userApi.createOnboardingUser(groupCode, userId).then(async (onboardingAuthDataStatus) => {
        if (
          onboardingAuthDataStatus &&
          onboardingAuthDataStatus.status === Status.done &&
          onboardingAuthDataStatus.data != null
        ) {
          // Get user data using token returned from creating onboarding user
          // Onboarding userId/Token cannot "getUser", so need to refresh first to get one that can
          const dataStatus$ = this._authApi
            .refreshToken(new AuthData().deserialize(onboardingAuthDataStatus.data))
            .pipe(
              map((refreshTokenResponse: DataStatus<AuthData>) => {
                const newAuthData = refreshTokenResponse.data;
                this._authData.updateToken(newAuthData);
                return new DataStatus<AuthData>(Status.done, new StatusMessage(200, 'OK'), newAuthData);
              })
            );
          const authDataStatus = await firstValueFrom(dataStatus$);
          if (authDataStatus.status === Status.done) {
            const updatedAuthData = await this._updateUserFromApi(authDataStatus?.data, {
              groupCode,
              liveSupport,
              theme,
            });
            if (updatedAuthData) {
              if (updatedAuthData.user) {
                if (updatedAuthData.user.type == null) {
                  // backup if backend does not send user type
                  updatedAuthData.user.type = UserType.onboarding;
                }
                if (
                  updatedAuthData.user?.settings?.language != null &&
                  updatedAuthData.user.settings.language !== this._dataStore.signalStore.currentLanguage()
                ) {
                  // maintain language setting on landing page even if a new user is created
                  if (!updatedAuthData.user.settings) {
                    updatedAuthData.user.settings = new Settings().deserialize({
                      language: this._dataStore.signalStore.currentLanguage(),
                    });
                  } else {
                    updatedAuthData.user.settings.language = this._dataStore.signalStore.currentLanguage();
                  }
                }
              }
              this._dataStore.setAuthStatus(
                new DataStatus<AuthData>(Status.done, new StatusMessage(HttpStatusCode.OK, 'OK'), updatedAuthData)
              );
              this._authData.updateToken(updatedAuthData);
            }
            this._userCreationStatus = Status.done;
            // refresh the new user's information if user information changed
            this._tagBloc.getUserTags();
            this._userCreationStatus = Status.done;
            this._analyticsBloc.userReadyForAnalytics();
          } else {
            console.warn(`There was an error creating an onboarding user: `);
            return new DataStatus<User>(
              Status.error,
              new StatusMessage(Status.error, `There was an error creating an onboarding user`),
              null
            );
          }
        } else {
          // error creating onboarding user
          console.warn(
            `There was an error creating an onboarding user: ${
              onboardingAuthDataStatus?.message?.message ?? 'unknown reason'
            }`
          );
          this._dataStore.setAuthStatus(
            new DataStatus<AuthData>(
              Status.error,
              new StatusMessage(
                Status.error,
                `There was an error creating an onboarding user: ${
                  onboardingAuthDataStatus?.message?.message ?? 'unknown reason'
                }`
              ),
              null
            )
          );
        }
        return null;
      });
    }
  }

  createOnboardingUserFromLink(): void {
    const destroy$ = new Subject<void>();
    if (![Status.starting, Status.done].includes(this._userCreationStatus)) {
      this._userCreationStatus = Status.starting;
      this._dataStore.setAuthStatus(
        new DataStatus<AuthData>(Status.starting, new StatusMessage(Status.starting, ''), null)
      );
      this._dataStore.authData$.pipe(takeUntil(destroy$)).subscribe(async (orgAuthData) => {
        if (orgAuthData) {
          const dataStatus$ = this._authApi.refreshToken(orgAuthData).pipe(
            map((refreshTokenResponse: DataStatus<AuthData>) => {
              const newAuthData = refreshTokenResponse.data;
              this._authData.updateToken(refreshTokenResponse.data);
              return new DataStatus<AuthData>(Status.done, new StatusMessage(200, 'OK'), newAuthData);
            })
          );
          const authDataStatus = await firstValueFrom(dataStatus$);
          if (authDataStatus.status === Status.done) {
            const updatedAuthData = await this._updateUserFromApi(authDataStatus?.data, null, destroy$);
            if (updatedAuthData) {
              if (updatedAuthData.user?.type == null) {
                // backup if backend does not send user type
                updatedAuthData.user.type = UserType.onboarding;
              }
              this._dataStore.setAuthStatus(
                new DataStatus<AuthData>(Status.done, new StatusMessage(Status.done, ''), updatedAuthData)
              );
              this._authData.updateToken(updatedAuthData);
            }
          } else {
            destroy$.next();
            this._dataStore.setAuthStatus(
              new DataStatus<AuthData>(Status.done, new StatusMessage(Status.done, ''), null)
            );
            this._userCreationStatus = Status.done;
          }
        } else {
          destroy$.next();
          this._dataStore.setAuthStatus(
            new DataStatus<AuthData>(Status.done, new StatusMessage(Status.done, ''), null)
          );
          this._userCreationStatus = Status.done;
        }
      });
    }
  }

  createGuestUser(group: Group, candidate: EligibilityCandidate): void {
    const groupCode = group?.groupCode;
    const currentStatus = this._dataStore.authStatus;

    // If currently loading or already successfully completed, do not queue up another
    // registration. Note: allow retries on errors.

    //TODO: update conditional once backend fix for sending userType is added
    if (
      (currentStatus && currentStatus.status === Status.starting) ||
      (this._dataStore?.authData?.token && this._dataStore?.authData?.user?.type === UserType.guest) ||
      this._dataStore?.authData?.user?.type === UserType.user
    ) {
      return;
    }

    if (!groupCode) {
      this._dataStore.setAuthStatus(
        new DataStatus(
          Status.error,
          new StatusMessage(Status.error, 'Missing group code or general token. Please refresh and try again.'),
          null
        )
      );
      return;
    }

    this._dataStore.setAuthStatus(new DataStatus(Status.starting, new StatusMessage(Status.starting, ''), null));

    const sharingValidation = this._browserStorage.getObject('sharingValidation') as SharingValidation;
    const candidateId = candidate?.candidateId;
    const isSharingValidation = !!sharingValidation;

    this._userApi
      .convertOnboardingToGuestUser(
        candidateId,
        groupCode,
        isSharingValidation ? sharingValidation.token : null,
        isSharingValidation ? 'sharing' : null
      )
      .then(async (response) => {
        if (response?.status === Status.done) {
          // get user information
          if (response?.data?.user) {
            const updatedAuthData = await this._updateUserFromApi(response?.data, group);
            if (updatedAuthData) {
              if (response?.data?.user?.type == null) {
                // backup if backend does not send user type
                response.data.user.type = UserType.guest;
              }
              this._dataStore.setAuthStatus(
                new DataStatus<AuthData>(Status.done, new StatusMessage(Status.done, ''), updatedAuthData)
              );
              this._authData.updateToken(updatedAuthData);
            }
          } else {
            this._authData.updateToken(response?.data);
          }
        }
        this._dataStore.setAuthStatus(response);
      })
      .catch((error) => {
        console.warn('error converting onboarding to guest user', error);
        this._dataStore.setAuthStatus(new DataStatus(Status.error, null, null));
      });
  }

  getPulseUser(groupCode: string) {
    this._userApi.registerPulseUser(groupCode).then(() => {
      this._analyticsBloc.userReadyForAnalytics();
    });
  }

  registerUser(email: string, confirmationCode: string): void {
    (super.registerUser(email, confirmationCode) as Promise<DataStatus<User>>)?.then((userStatus) => {
      this._registerStatus$.next(
        new DataStatus(userStatus.status, userStatus.message, userStatus?.status === Status.done)
      );
      if (userStatus?.status === Status.done) {
        // set flag in order to ensure password gets set during onboarding
        this._onboardingUtilities.saveOnboardingData({ passwordSetRequired: true });
      }
    });
  }

  signUp(email: string): void {
    super.signUp(email);
  }

  hasSetPassword(checkRemoteStatus?: boolean, userId?: string): void {
    const destroy$ = new Subject<void>();
    const passwordSetRequiredLocal = this._onboardingUtilities.onboardingData?.passwordSetRequired;

    if (checkRemoteStatus && userId) {
      let isInitialUserPass = true;
      let updateUserInfo = true;
      this._dataStore.user$.pipe(takeUntil(destroy$)).subscribe((user) => {
        if (isInitialUserPass) {
          if (
            user?.provider &&
            user?.provider !== NON_SSO_AUTH_PROVIDER.other &&
            user?.provider !== NON_SSO_AUTH_PROVIDER.Cognito
          ) {
            // only non-SSO auth providers can set a password, exit if otherwise
            this._onboardingPasswordSetRequired$.next(false);
            return;
          }
          // update User info if there isn't any user info or the userType is undefined
          updateUserInfo = user?.type == null;
        }
        if (!isInitialUserPass || !updateUserInfo) {
          // go through on first pass if user info doesn't need to be updated
          //   (i.e. have a type, and it's a user),
          // otherwise only on the second pass
          if (
            user?.type !== UserType.user &&
            (user?.provider === NON_SSO_AUTH_PROVIDER.other || user?.provider === NON_SSO_AUTH_PROVIDER.Cognito)
          ) {
            // only UserType.user can validly use the set-password endpoint,
            // and only non-SSO auth providers can set a password,
            // so no other user type can require it
            this._onboardingPasswordSetRequired$.next(false);
            return;
          }
          (super.hasSetPassword() as Promise<DataStatus<boolean>>)
            .then((hasSetPasswordStatus) => {
              if (hasSetPasswordStatus?.status !== Status.starting) {
                this._onboardingPasswordSetRequired$.next(hasSetPasswordStatus?.data);
                this._onboardingUtilities.saveOnboardingData({ passwordSetRequired: hasSetPasswordStatus?.data });
                destroy$.next();
                destroy$.complete();
              }
            })
            .catch(() => {
              // not a high change of error as this API returns 200 for most calls, but ensure the observable gets populated
              this._onboardingPasswordSetRequired$.next(passwordSetRequiredLocal);
              destroy$.next();
              destroy$.complete();
            });
        }
        isInitialUserPass = false;
      });
      if (updateUserInfo) {
        // ensure user is up-to-date before checking password
        this.getUser(userId);
      }
    } else {
      // if not checking remote status, just update the observable with the local value
      this._onboardingPasswordSetRequired$.next(passwordSetRequiredLocal);
    }
  }

  setPassword(password: string): void {
    (super.setPassword(password) as Promise<DataStatus<boolean>>)?.then((passwordSetStatus) => {
      if (passwordSetStatus?.status !== Status.starting) {
        this._passwordChanged$.next(passwordSetStatus);
        if (!passwordSetStatus?.data) {
          // if there was a failure to set the password, ensure it's still noted
          this._onboardingUtilities.saveOnboardingData({ passwordSetRequired: passwordSetStatus?.data });
        }
      }
      if (passwordSetStatus?.status === Status.done && passwordSetStatus?.data) {
        // user is registered and password is set, clean up and complete
        this._postSignupCompletion();
      }
    });
  }

  updateUserTheme() {
    this._themeService.theme$.pipe(take(1)).subscribe((theme) => {
      super.updateUserTheme(theme);
    });
  }

  private _setEmailIfExists(user: User, emailField?: string): boolean {
    const existingEmail =
      ((user as JsonObject)?.[emailField] as string) ||
      (user?.email as string) ||
      (user?.proposedEmail as string) ||
      (user?.subscriptionPlanEmail as string);
    if (existingEmail) {
      this._existingUserEmail$.next(existingEmail);
    }
    return !!existingEmail;
  }

  private _postSignupCompletion() {
    this._onboardingUtilities.removeOnboardingData();
    this._landingDynamicBloc.resetLanding();
    this._firstVisitBloc.clear(); // new user fully created, clear First Visit
  }

  private _updateUserFromApi(
    authData: AuthData,
    groupData?: Partial<Group>,
    destroy$?: Subject<void>
  ): Promise<AuthData | void> {
    return this._userApi
      .getUser(authData?.user?.id, authData?.token)
      .then((user) => {
        if (user) {
          destroy$?.next();
          // Note: since this is fetching data about the authData user,
          // should not need checks to verify it is the same user
          // before merging
          authData.user = authData.user?.combine?.(user) ?? user;
          return new AuthData().deserialize({
            ...authData,
            user: {
              ...authData.user,
              group: groupData
                ? new Group().deserialize({ ...authData.user.group, ...groupData })
                : authData.user.group,
            },
          });
        }
        this._userCreationStatus = Status.done;
        return null;
      })
      .catch((err): null => {
        console.warn('There was an error updating user information (onboarding, guest user creation):', err);
        this._userCreationStatus = Status.error;
        destroy$?.next();
        return null;
      });
  }
}

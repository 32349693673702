import { envVariables } from '@kp/../environments/environment-variables';
import { CURRENT_CHECKIN_DIALOGUE_LOGIC_KEY, DialogueLogicKey } from '@kp/dialogue/models/dialogue.model';
import { ImageType } from '@kp/shared/image/image.bloc';
import { Product } from '@ktypes/enums';
import { JsonObject, MapCategory, NavItem, NavItemType, TagKey } from '@ktypes/models';

export interface QuickAction {
  queryParams: JsonObject<string>;
  returnUrl: string | boolean;
  route: string;
  svgUrl: string;
  text: string;
  description?: string;
  tags?: TagKey[];
}

export enum FilterFeature {
  challenge = 'challenge',
  default = 'default',
  focus = 'focus',
  intention = 'intention',
}

export interface Browser {
  displayName?: string;
  name?: string;
  version?: number;
  os?: string[];
  matchExact?: boolean;
  recommend?: boolean;
  icon?: string;
  url?: string;
}

export class Constants {
  static rootDomain = `${
    (envVariables as { [key: string]: string }).PRODUCT === Product.resourceful ? 'resourceful' : 'purposeful'
  }.kumanu.com`;
  static focusRefKey = '4cUELRf7xSuxjKuExOf305';
  static lifeQuestKey = 'life_purpose_quest_v1';
  static workQuestKey = 'work_purpose_quest_v1';
  static intentionRefKey = '6911QMbY050RL30bCkPinH';
  static journalRefKey = '4RrHp0hbJLuOtmtHxwp66E';
  static feedbackLinkBase =
    'https://www.surveygizmo.com/s3/5029608/Purposeful-Content-Feedback-Form?title={{cardTitle}}';
  static purposefulStaticPlayStoreUrl = 'https://play.google.com/store/apps/details?id=com.kumanu.purposeful';
  static ssoLoginUrlTemplate = `$authHost/oauth2/authorize?identity_provider=$provider&redirect_uri=$kumanuHost&response_type=$response_type&client_id=$clientId&state=$state&scope=email%20openid%20phone%20profile`;
  static ssoLogoutUrlTemplate = '$authHost/logout?client_id=$clientId&logout_uri=$kumanuLogout';
  static ssoLogoutAndReloginUrlTemplate =
    '$authHost/logout?identity_provider=$provider&redirect_uri=$kumanuHost&response_type=$response_type&client_id=$clientId&state=$state&scope=email%20openid%20phone%20profile';
  // Dynamic link url parameters: https://firebase.google.com/docs/dynamic-links/create-manually
  static mobileTransferLinkBase = 'https://kumanu.page.link/?link=';
  static mobileTransferDynamicLinkTemplate = '$windowLocationOrigin/groupCode?groupCode=$groupCode&userId=$userId';
  static mobileTransferDynamicLinkQueryParamsTemplate =
    '&apn=com.kumanu.purposeful&isi=1457841328&ibi=com.kumanu.purposeful&st=$socialTitle&sd=$socialCTA';
  static googleMapsDirectionsLinkBase = 'https://www.google.com/maps/dir/?api=1&';
  static dialogueSystemKey = 'purposeful_web';
  static imgTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/webp'];
  static states = [
    'AK',
    'AL',
    'AR',
    'AS',
    'AZ',
    'CA',
    'CO',
    'CT',
    'DC',
    'DE',
    'FL',
    'GA',
    'GU',
    'HI',
    'IA',
    'ID',
    'IL',
    'IN',
    'KS',
    'KY',
    'LA',
    'MA',
    'MD',
    'ME',
    'MI',
    'MN',
    'MO',
    'MP',
    'MS',
    'MT',
    'NC',
    'ND',
    'NE',
    'NH',
    'NJ',
    'NM',
    'NV',
    'NY',
    'OH',
    'OK',
    'OR',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UM',
    'UT',
    'VA',
    'VI',
    'VT',
    'WA',
    'WI',
    'WV',
    'WY',
  ];

  static supportedBrowsers: Browser[] = [
    {
      displayName: 'Edge',
      name: 'MS-Edge-Chromium',
      version: 80,
      os: ['Windows', 'Mac'],
      matchExact: false,
      recommend: true,
      icon: '/assets/icons/browsers/edge.png',
      url: 'https://www.microsoft.com/en-us/edge/',
    },
    {
      displayName: 'Edge',
      name: 'MS-Edge',
      version: 80,
      os: ['Android', 'iOS'],
      matchExact: false,
      recommend: true,
      icon: '/assets/icons/browsers/edge.png',
      url: 'https://www.microsoft.com/en-us/edge/',
    },
    {
      displayName: 'Chrome',
      name: 'Chrome',
      version: 0,
      os: [],
      matchExact: false,
      recommend: true,
      icon: '/assets/icons/browsers/chrome.png',
      url: 'https://www.google.com/chrome/',
    },
    {
      displayName: 'Firefox',
      name: 'Firefox',
      version: 0,
      os: [],
      matchExact: false,
      recommend: true,
      icon: '/assets/icons/browsers/firefox.png',
      url: 'https://www.mozilla.org/en-US/firefox/new/',
    },
    {
      displayName: 'Safari',
      name: 'Safari',
      version: 12,
      os: ['Mac', 'iOS'],
      matchExact: false,
      recommend: true,
      icon: '/assets/icons/browsers/safari.png',
      url: 'https://support.apple.com/downloads/safari',
    },
  ];

  static platformName = 'Web';

  /**
   * -- static quickActions: QuickAction[]
   * t(AI Coach)
   * t(Coaching customized just for you.)
   * t(Check in)
   * t(Pause to reflect track your progress and get insights and feedback to thrive.)
   * t(Log an action)
   * t(Complete Actions and Quests to build positive habits and gain valuable skills.)
   * t(Set an intention)
   * t(Set a goal for living into your strengths or practicing new ones.)
   * t(Add a photo)
   * t(Upload moments memories and images that inspire you to be your best.)
   * t(Journal)
   * t(Record your thoughts feelings and actions to document your journey.)
   */
  static quickActions: QuickAction[] = [
    {
      queryParams: { logicKey: DialogueLogicKey.gpt_coach_checkin },
      returnUrl: true,
      route: '/dialogue',
      svgUrl: '/assets/icons/light-bulb.svg',
      tags: [TagKey.exploratory_generative_ai],
      text: 'AI Coach',
      description: 'Coaching customized just for you.',
    },
    {
      queryParams: { logicKey: CURRENT_CHECKIN_DIALOGUE_LOGIC_KEY },
      returnUrl: true,
      route: '/dialogue',
      svgUrl: '/assets/icons/smiley-grin-reversed.svg',
      text: 'Check in',
      description: 'Pause to reflect, track your progress, and get insights and feedback to thrive.',
    },
    {
      queryParams: {},
      returnUrl: false,
      route: '/cards/take-action',
      svgUrl: '/assets/icons/checkmark-empty-circle.svg',
      text: 'Log an action',
      description: 'Complete Actions and Quests to build positive habits and gain valuable skills.',
    },
    {
      queryParams: {
        logicKey: DialogueLogicKey.templated_dialogue,
        refKey: Constants.intentionRefKey,
      },
      returnUrl: true,
      route: '/dialogue',
      svgUrl: '/assets/icons/compass.svg',
      text: 'Set an intention',
      description: 'Set a goal for living into your strengths or practicing new ones.',
    },
    {
      queryParams: { action: 'add_photo' },
      returnUrl: false,
      route: '/me/photos',
      svgUrl: '/assets/icons/mountain-image-add.svg',
      text: 'Add a photo',
      description: 'Upload moments, memories and images that inspire you to be your best.',
    },
    {
      queryParams: { logicKey: DialogueLogicKey.templated_dialogue, refKey: Constants.journalRefKey },
      returnUrl: true,
      route: '/dialogue',
      svgUrl: '/assets/icons/journal.svg',
      text: 'Journal',
      description: 'Record your thoughts, feelings and actions to document your journey.',
    },
  ];

  static appSpecificRoutes: { [key: string]: string[] } = {
    [Product.resourceful]: ['/resources'],
    [Product.purposeful]: [
      '/challenges',
      '/explore',
      '/discover',
      '/me',
      '/mobile-app-info',
      '/purposeful-days',
      '/progress',
      '/reminders',
      '/today',
      '/welcome/mobile',
      '/welcome/mobile-app/interstitial',
      '/welcome/mobile-app/interstitial/mobile',
    ],
  };

  static navItemList: NavItem[] = [
    {
      link: '/today',
      icon: '/assets/icons/icons-nav-today.svg',
      type: NavItemType.standard,
      title: 'Today' /** t(Today) */,
      displayOrder: { purposeful: { core: 10 } },
      mapCategory: MapCategory.main,
      children: [
        {
          link: '/take-action',
          icon: '',
          type: NavItemType.child,
          title: 'Take Action' /** t(Take Action) */,
          displayOrder: { purposeful: { core: 11 } },
        },
        {
          link: '/purposeful-days',
          icon: '',
          type: NavItemType.child,
          title: 'Purposeful Days' /** t(Purposeful Days) */,
          displayOrder: { purposeful: { core: 12 } },
        },
      ],
    },
    {
      link: '/discover',
      icon: '/assets/icons/icons-nav-discover.svg',
      type: NavItemType.standard,
      title: 'Discover' /** t(Discover) */,
      displayOrder: { purposeful: { core: 20 } },
      mapCategory: MapCategory.main,
      children: [
        {
          link: '/discover',
          queryParams: { section: 'search' },
          type: NavItemType.child,
          title: 'Search' /** t(Search) */,
          displayOrder: { purposeful: { core: 21 } },
        },
        {
          link: '/discover',
          queryParams: { section: 'totd' },
          type: NavItemType.child,
          title: 'Tip of the Day' /** t(Tip of the Day) */,
          displayOrder: { purposeful: { core: 22 } },
        },
        {
          link: '/discover',
          queryParams: { section: 'browse' },
          type: NavItemType.child,
          title: 'Browse things to try' /** t(Browse things to try) */,
          displayOrder: { purposeful: { core: 23 } },
        },
      ],
    },
    {
      type: NavItemType.quickActions,
      title: 'Quick Actions' /** t(Quick Actions) */,
      displayOrder: { purposeful: { core: 30 } },
      mapCategory: MapCategory.quickActions,
      children: Constants.quickActions.map((quickAction, index) => {
        const queryParams =
          typeof quickAction.returnUrl === 'string'
            ? { ...quickAction.queryParams, returnUrl: quickAction.returnUrl }
            : quickAction.queryParams;
        return {
          icon: quickAction.svgUrl,
          link: quickAction.route,
          queryParams,
          type: NavItemType.child,
          title: quickAction.text,
          tags: quickAction.tags,
          displayOrder: { purposeful: { core: 30 + index + 1 } },
        };
      }),
    },
    {
      link: '/progress',
      icon: '/assets/icons/icons-nav-progress.svg',
      type: NavItemType.standard,
      title: 'Progress' /** t(Progress) */,
      displayOrder: { purposeful: { core: 40 } },
      mapCategory: MapCategory.main,
      children: [
        {
          link: '/progress/history/day',
          icon: '',
          type: NavItemType.child,
          title: 'Daily History' /** t(Daily History) */,
          displayOrder: { purposeful: { core: 41 } },
        },
        {
          link: '/progress/history/month',
          icon: '',
          type: NavItemType.child,
          title: 'Monthly History' /** t(Monthly History) */,
          displayOrder: { purposeful: { core: 42 } },
        },
      ],
    },
    {
      link: '/me',
      icon: '/assets/icons/icons-nav-me.svg',
      type: NavItemType.standard,
      imageType: ImageType.user_profile,
      title: 'Me' /** t(Me) */,
      mapTitle: 'Me / Profile' /** t(Me / Profile) */,
      displayOrder: { purposeful: { core: 50 } },
      mapCategory: MapCategory.main,
      children: [
        {
          link: '/me/photos',
          icon: '',
          type: NavItemType.child,
          title: 'My Photos' /** t(My Photos) */,
          displayOrder: { purposeful: { core: 51 } },
        },
        {
          link: '/me/edit',
          icon: '',
          type: NavItemType.child,
          title: 'Edit Profile' /** t(Edit Profile) */,
          displayOrder: { purposeful: { core: 41 } },
        },
      ],
    },
    {
      link: '/resources',
      icon: '/assets/icons/resources.svg',
      type: NavItemType.standard,
      title: 'Resources' /** t(Resources) */,
      displayOrder: { resourceful: { side: 10 } },
      mapCategory: MapCategory.main,
      external: false,
    },
    {
      link: '/cards/saved',
      icon: '/assets/icons/save-filled.svg',
      type: NavItemType.standard,
      title: 'Saved' /** t(Saved) */,
      displayOrder: { purposeful: { side: 10 }, resourceful: { side: 20 } },
      external: false,
      mapCategory: MapCategory.feature,
    },
    {
      link: '/challenges',
      icon: '/assets/icons/mountain.svg',
      type: NavItemType.challenges,
      title: 'Challenges' /** t(Challenges) */,
      displayOrder: { purposeful: { side: 20 } },
      external: false,
      mapCategory: MapCategory.feature,
      tags: [TagKey.social_challenges_enabled],
    },
    {
      link: '/report',
      icon: '/assets/icons/report-new.svg',
      type: NavItemType.standard,
      title: 'Report' /** t(Report) */,
      displayOrder: { purposeful: { side: 30 }, resourceful: { side: 30 } },
      external: false,
      mapCategory: { purposeful: MapCategory.feature, resourceful: MapCategory.main },
    },
    {
      link: '/invite',
      icon: '/assets/icons/mail.svg',
      type: NavItemType.invites,
      title: 'Invite' /** t(Invite) */,
      displayOrder: { purposeful: { side: 35 } },
      external: false,
      mapCategory: MapCategory.feature,
    },
    {
      link: '/reminders',
      icon: '/assets/icons/notification.svg',
      type: NavItemType.standard,
      title: 'Notifications' /** t(Notifications) */,
      displayOrder: { purposeful: { side: 40 } },
      external: false,
      mapCategory: MapCategory.feature,
    },
    {
      link: '/settings',
      icon: '/assets/icons/gear.svg',
      type: NavItemType.standard,
      title: 'Settings' /** t(Settings) */,
      displayOrder: { purposeful: { side: 50 }, resourceful: { side: 40 } },
      external: false,
      mapCategory: MapCategory.support,
    },
    {
      link: '/help',
      icon: '/assets/icons/question.svg',
      type: NavItemType.standard,
      title: 'Help' /** t(Help) */,
      displayOrder: { purposeful: { side: 60 }, resourceful: { side: 50 } },
      external: false,
      mapCategory: MapCategory.support,
    },
    {
      link: '/feedback',
      icon: '/assets/icons/feedback.svg',
      type: NavItemType.standard,
      title: 'Feedback' /** t(Feedback) */,
      displayOrder: { purposeful: { side: 70 }, resourceful: { side: 60 } },
      external: false,
      mapCategory: MapCategory.support,
    },
    {
      link: '/site-map',
      icon: '/assets/icons/globe.svg',
      type: NavItemType.standard,
      title: 'Site Map' /** t(Site Map) */,
      displayOrder: { purposeful: { side: 80 }, resourceful: { side: 70 } },
      external: false,
      hideFromMap: true,
    },
    {
      link: 'https://www.kumanu.com/about-us/',
      icon: '/assets/icons/about.svg',
      type: NavItemType.standard,
      title: 'About Kumanu' /** t(About Kumanu) */,
      displayOrder: { purposeful: { side: 90 } },
      external: true,
      mapCategory: MapCategory.support,
    },
    {
      link: '/mobile-app-info',
      icon: '/assets/icons/mobile-icon.svg',
      type: NavItemType.standard,
      title: 'Purposeful Mobile App' /** t(Purposeful Mobile App) */,
      displayOrder: { purposeful: { side: 100 } },
      external: false,
      mapCategory: MapCategory.support,
    },
    {
      link: '/privacy',
      type: NavItemType.tertiary,
      title: 'Privacy' /** t(Privacy) */,
      mapTitle: 'Privacy Policy' /** t(Privacy Policy) */,
      displayOrder: { purposeful: { side: 110 } },
      external: false,
      mapCategory: MapCategory.support,
      hideFromNav: true,
    },
    {
      link: '/eula',
      type: NavItemType.tertiary,
      title: 'Acceptable Use' /** t(Acceptable Use) */,
      mapTitle: 'Acceptable Use Policy' /** t(Acceptable Use Policy) */,
      displayOrder: { purposeful: { side: 120 } },
      external: false,
      mapCategory: MapCategory.support,
      hideFromNav: true,
    },
  ];

  /**
   * -- static recommendedCardCategory: FilteredCardCategory
   * t(recommendedCardCategory.FilterFeature.default.title)
   * t(recommendedCardCategory.FilterFeature.default.description)
   * t(recommendedCardCategory.FilterFeature.challenge.title)
   * t(recommendedCardCategory.FilterFeature.challenge.description)
   * t(recommendedCardCategory.FilterFeature.focus.title)
   * t(recommendedCardCategory.FilterFeature.focus.description)
   * t(recommendedCardCategory.FilterFeature.intention.title)
   * t(recommendedCardCategory.FilterFeature.intention.description)
   */

  /**
   * -- static takeActionCardCategory: FilteredCardCategory
   * t(takeActionCardCategory.FilterFeature.default.title)
   * t(takeActionCardCategory.FilterFeature.default.description)
   * t(takeActionCardCategory.FilterFeature.challenge.title)
   * t(takeActionCardCategory.FilterFeature.challenge.description)
   * t(takeActionCardCategory.FilterFeature.focus.title)
   * t(takeActionCardCategory.FilterFeature.focus.description)
   * t(takeActionCardCategory.FilterFeature.intention.title)
   * t(takeActionCardCategory.FilterFeature.intention.description)
   */

  static searchStrings = [
    'Try Purpose',
    'Try Mindfulness',
    'Try Gratitude',
    'Try Meditations',
    'Try Energy',
    'Try Sleep',
    'Try Work',
    'Try Relationships',
    'Try Stress',
    'Try Reflections',
    'Try Life balance',
    'Try Resilience',
    'Try Family',
    'Try Performance',
    'Try Leadership',
    'Try Productivity',
    'Try Community',
    'Try Health',
    'Try Communication',
    'Try Transcendence',
    'Try Helping others',
    'Try Self care',
  ];

  /**
   * -- static searchStrings: string[]
   * t(Try Purpose)
   * t(Try Mindfulness)
   * t(Try Gratitude)
   * t(Try Meditations)
   * t(Try Energy)
   * t(Try Sleep)
   * t(Try Work)
   * t(Try Relationships)
   * t(Try Stress)
   * t(Try Reflections)
   * t(Try Life balance)
   * t(Try Resilience)
   * t(Try Family)
   * t(Try Performance)
   * t(Try Leadership)
   * t(Try Productivity)
   * t(Try Community)
   * t(Try Health)
   * t(Try Communication)
   * t(Try Transcendence)
   * t(Try Helping others)
   * t(Try Self care)
   */
  get randomSearchText() {
    return Constants.searchStrings[Math.floor(Math.random() * Constants.searchStrings.length)];
  }

  static get shuffledBrowserArray(): Browser[] {
    return this.shuffleArray(Constants.supportedBrowsers);
  }

  // Add additional types as necessary (in accordance with linting rules)
  static shuffleArray(array: Browser[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
}

/**
 * -- static searchStrings: string[]
 * t(Resources, Tips, Quests, and more)
 */
export const recommendedText = {
  default: 'Resources, Tips, Quests, and more',
  resources: 'Resources, Tips, Quests, and more',
};

/**
 * -- static PREDEFINED_SEARCH_TITLE: string[]
 * t(What would you like help with?)
 */
export const PREDEFINED_SEARCH_TITLE = 'What would you like help with?';

@if (
  {
    currentDialogueBlock: (dialogueBloc.currentDialogueBlock$ | async),
    dialogue: (dialogueBloc.dialogue$ | async)
  };
  as headerInfo
) {
  <header class="top-header">
    <div
      class="header-container header-left"
      [ngClass]="{
        'loading': headerInfo.dialogue?.status === Status.starting,
        'has-center-content':
          headerInfo.currentDialogueBlock?.hasAiCoach && headerInfo.dialogue?.status !== Status.starting
      }">
      <a [routerLink]="logoUrl" class="logo" [attr.title]="productName">
        <svg-icon [src]="(themeService.theme$ | async)?.onBackgroundIconUrl()"></svg-icon>
      </a>
    </div>
    @if (globalElementsService.showBasicLogoHeaderWithIcons$ | async) {
      <div class="header-container header-right">
        <button class="logo" (click)="showFeedbackModal = true">
          <svg-icon src="/assets/icons/feedback.svg"></svg-icon>
        </button>
        <button class="logo" (click)="showHelpModal = true">
          <svg-icon src="/assets/icons/question-filled-circle.svg"></svg-icon>
        </button>
      </div>
    }
  </header>
}

@if (showFeedbackModal) {
  <kui-modal [isOpen]="showFeedbackModal" (isOpenChange)="closeFeedbackModalIfOpen()" type="info">
    <div class="modal-container modal-feedback">
      <kp-feedback></kp-feedback>
    </div>
  </kui-modal>
}

@if (showHelpModal) {
  <kui-modal
    [analyticsData]="[analyticsData, analyticEvent.help_dialog_opened, analyticEvent.help_dialog_closed]"
    [isOpen]="showHelpModal"
    (isOpenChange)="closeHelpModalIfOpen()"
    type="info">
    <div class="modal-container">
      <kp-help></kp-help>
    </div>
  </kui-modal>
}

import { Deserializable, JsonObject, Serializable } from '@ktypes/models';
import { DateTimeUtil } from '@kutil';

export class PurposefulDay implements Deserializable<JsonObject, PurposefulDay>, Serializable<JsonObject> {
  constructor(
    public date?: Date,
    public actionCompleted?: boolean,
    public challengeComplete?: boolean,
    public checkedIn?: boolean,
    public imageUploaded?: boolean,
    public intentionSet?: boolean,
    public journaled?: boolean,
    public reflectOnDay?: boolean,
    public showedUp?: boolean
  ) {}

  public purposefulDayCompleted() {
    return (
      this.challengeComplete ||
      this.checkedIn ||
      this.actionCompleted ||
      this.imageUploaded ||
      this.intentionSet ||
      this.journaled
    );
  }

  public deserialize(input: JsonObject): PurposefulDay {
    if (input == null) {
      return null;
    }

    this.date = DateTimeUtil.convertUTCDateToLocalDate(new Date(input.date));
    this.actionCompleted = !!input['actionCompleted'];
    this.challengeComplete = !!input['challengeComplete'];
    this.checkedIn = !!input['checkedIn'];
    this.imageUploaded = !!input['imageUploaded'];
    this.intentionSet = !!input['intentionSet'];
    this.journaled = !!input['journaled'];
    this.reflectOnDay = !!input['reflectOnDay'];
    this.showedUp = !!input['showedUp'];

    return this;
  }

  public serialize(): JsonObject {
    const json: JsonObject = {};

    json['date'] = this.date;
    json['actionCompleted'] = this.actionCompleted;
    json['challengeComplete'] = this.challengeComplete;
    json['checkedIn'] = this.checkedIn;
    json['imageUploaded'] = this.imageUploaded;
    json['intentionSet'] = this.intentionSet;
    json['journaled'] = this.journaled;
    json['reflectOnDay'] = this.reflectOnDay;
    json['showedUp'] = this.showedUp;

    return json;
  }
}

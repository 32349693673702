import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi, RequestType } from '@kapi';
import { EnvironmentVariablesService } from '@kenv';
import { ImageRecord } from '@kp/shared/image/image-record.model';
import { ImageType } from '@kp/shared/image/image.bloc';
import { DataStoreService } from '@kservice';
import { HttpStatusCode } from '@ktypes/enums';
import { DataStatus, Status, StatusMessage } from '@ktypes/models';
import { DateTimeUtil } from '@kutil';
import { Observable, firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ImageApi extends BaseApi {
  constructor(
    client: HttpClient,
    dataStoreService: DataStoreService,
    environmentVariablesService: EnvironmentVariablesService
  ) {
    super(client, dataStoreService, environmentVariablesService);
  }

  async refreshImages(
    imageType: ImageType,
    options: { latest?: boolean; multiple?: boolean; random?: boolean }
  ): Promise<DataStatus<ImageRecord | ImageRecord[]>> {
    let path = '';
    if (options.latest) {
      path = '/image';
    } else if (options.multiple) {
      path = '/images';
    } else if (options.random) {
      path = '/image/random';
    }
    const url = this.buildUrl(path, true, { queryParams: { imageType } });

    const request$ = this._getImageRequest<ImageRecord>(url);

    return await firstValueFrom(request$).catch((error): null => {
      console.warn('Error getting random user image(s):', error);
      return null;
    });
  }

  async uploadUserImage(file: File | Blob, imageType: ImageType): Promise<DataStatus<ImageRecord>> {
    const formData = new FormData();
    const url = this.buildUrl('/image', true, {
      queryParams: { imageType, deviceCreatedTimestamp: DateTimeUtil.formatInLocal() },
    });
    // Workaround for `base64ToFile` creating an image with the file name of "blob"
    formData.append('imageFile', file, getFilename(file));

    const request$ = this.performRequest<ImageRecord>(RequestType.POST, url, {
      requestBody: formData,
      includeToken: true,
      excludeContentType: true,
    }).pipe(
      map((response: HttpResponse<ImageRecord>): DataStatus<ImageRecord> => {
        if (response?.ok) {
          return new DataStatus<ImageRecord>(
            Status.done,
            new StatusMessage(HttpStatusCode.OK, response.statusText),
            new ImageRecord().deserialize(response?.body)
          );
        }
        return new DataStatus<ImageRecord>(
          Status.error,
          new StatusMessage(
            response?.status || HttpStatusCode.INTERNAL_SERVER_ERROR,
            response?.statusText || 'Error uploading image'
          ),
          null
        );
      })
    );

    return await firstValueFrom(request$).catch((error) => {
      console.warn('Error uploading user image:', error);
      return new DataStatus<ImageRecord>(
        Status.error,
        new StatusMessage(HttpStatusCode.INTERNAL_SERVER_ERROR, 'Error uploading image'),
        null
      );
    });
  }

  async deleteUserImage(imageId: string): Promise<DataStatus<boolean>> {
    const url = this.buildUrl(`/image/${imageId}`, true);
    const request$ = this.performRequest(RequestType.DELETE, url, { includeToken: true }).pipe(
      map((response) => {
        if (response?.ok) {
          return new DataStatus<boolean>(Status.done, new StatusMessage(response.status, 'OK'), true);
        }
        return new DataStatus<boolean>(
          Status.error,
          new StatusMessage(response.status, response.statusText || `${response.status}: Error deleting user image`),
          false
        );
      })
    );
    return await firstValueFrom(request$).catch((error) => {
      console.warn('Error deleting user image:', error);
      return new DataStatus<boolean>(
        Status.error,
        new StatusMessage(HttpStatusCode.INTERNAL_SERVER_ERROR, 'Error deleting image'),
        false
      );
    });
  }

  private _getImageRequest<T>(url: string) {
    return this.performRequest<T>(RequestType.GET, url, { includeToken: true }).pipe(
      map(this._handleImageResponse.bind(this) as () => DataStatus<T>),
      catchError(this._handleImageError.bind(this))
    );
  }

  private _handleImageResponse(response: HttpResponse<ImageRecord>): DataStatus<ImageRecord | ImageRecord[]> {
    if (response?.ok) {
      const records = Array.isArray(response.body)
        ? response.body?.map?.((imageRecord) => new ImageRecord().deserialize(imageRecord))
        : [new ImageRecord().deserialize(response.body)];
      return new DataStatus(
        Status.done,
        new StatusMessage(HttpStatusCode.OK, ''),
        records.length === 1 ? records[0] : records
      );
    }
    return new DataStatus<ImageRecord>(
      Status.error,
      new StatusMessage(HttpStatusCode.INTERNAL_SERVER_ERROR, 'There was an error retrieving your image(s)'),
      null
    );
  }

  private _handleImageError(errorResult: HttpErrorResponse): Observable<DataStatus<ImageRecord>> {
    if (errorResult.status === HttpStatusCode.NOT_FOUND) {
      // 404 / NOT_FOUND is expected result if there is no image yet, so not an error
      return of(
        new DataStatus<ImageRecord>(Status.done, new StatusMessage(HttpStatusCode.NOT_FOUND, 'Not Found'), null)
      );
    }
    return of(
      new DataStatus<ImageRecord>(
        Status.error,
        new StatusMessage(HttpStatusCode.INTERNAL_SERVER_ERROR, 'There was a server error retrieving your image(s)'),
        null
      )
    );
  }
}

export function getFilename(file: File | Blob): string {
  if (file instanceof File) {
    return file.name;
  }
  const root = `image-${new Date().toISOString().replace(/[:.]/g, '')}`;
  const type = file.type;
  // expecting "image/png", "image/jpeg", "image/webp", or "image/gif" - default to png
  const extension = type?.includes('image/') ? type?.split('/')[1] : 'png';
  return `${root}.${extension}`;
}

import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AnalyticEvent, AnalyticsBloc } from '@kanalytics';
import { NavigationBloc } from '@kbloc';
import { EnvironmentVariablesService } from '@kenv';
import { Constants } from '@kp/shared/constants.service';
import { UserBloc } from '@kp/user/user.bloc';
import { ThemeService, WINDOW, productDisplayName } from '@kservice';
import { AppSwitchLocation, Product } from '@ktypes/enums';
import { ProductInfo } from '@ktypes/models';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss'],
})
export class SiteHeaderComponent {
  constructor(
    private _analyticsBloc: AnalyticsBloc,
    @Inject(DOCUMENT) private _document: Document,
    private _environmentVariablesService: EnvironmentVariablesService,
    public navigationBloc: NavigationBloc,
    public themeService: ThemeService,
    private _userBloc: UserBloc,
    @Inject(WINDOW) private _window: Window
  ) {}

  @Input() navActive: boolean;
  @Input() quickActionsOpen = false;
  @Output() navBtnClick = new EventEmitter<void>();
  @Output() quickActionsToggled = new EventEmitter<boolean>();

  constants = Constants;
  externalProduct = this._environmentVariablesService.product;
  readonly moreApps = toSignal(this.navigationBloc.moreApps$);
  product = Product;
  productName = productDisplayName;

  onNavBtnClick() {
    if (this.navActive) {
      (this._window.document.activeElement as HTMLElement).blur();
    }
    this.navBtnClick.emit();
    this.quickActionsToggled.emit(false);
  }

  appClicked(app: ProductInfo) {
    if (!app?.currentApp) {
      this._userBloc.switchApplication(app.product);
      const event = this._analyticsBloc.createEventFromInteraction({
        event: AnalyticEvent.app_switch_requested,
        meta: {
          app_requested: app?.product,
          link_location: AppSwitchLocation.app_switcher,
          source_app: this.externalProduct,
        },
      });

      this._analyticsBloc.sendEvent(event);
    }
  }

  sideNavTab(e: Event) {
    if (this.navActive && !this.quickActionsOpen) {
      (this._document.querySelectorAll('.sidebar-nav .nav-item')?.[0] as HTMLElement).focus();
      e.preventDefault();
    }
  }
}

export const MockSiteHeaderComponent = MockComponent({
  selector: 'kp-site-header',
  inputs: ['navActive', 'quickActionsOpen'],
});

<ng-container *transloco="let t">
  <button class="button button-quick-actions" aria-label="Quick Actions Menu" (click)="toggleQuickActions()">
    <span class="button-plus" [ngClass]="{ 'button-x': quickActionsOpen }"></span>
  </button>

  @if (quickActionsOpen) {
    <div class="menu-container">
      @for (quickAction of quickActions; track quickAction; let last = $last) {
        <a
          class="quick-action"
          (keydown.enter)="toggleQuickActions(quickAction)"
          (click)="toggleQuickActions(quickAction)"
          [routerLink]="quickAction.route"
          [queryParams]="quickAction.queryParams"
          (keydown.tab)="last ? toggleQuickActions() : true">
          <span class="quick-action-text">
            <svg-icon class="icon-quick-action" [src]="quickAction.svgUrl"></svg-icon>
            {{ t(quickAction.text) }}
          </span>
        </a>
      }
    </div>
  }
  <kui-a11y-live-announce [announcement]="t(quickActionsAnnouncement)"></kui-a11y-live-announce>
</ng-container>

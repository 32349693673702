import { Injectable } from '@angular/core';
import { LegalDocumentsBloc } from '@kbloc';
import { GroupValidationBloc } from '@kp/auth/group-validation.bloc';
import { AuthDataService, BrowserStorage } from '@kservice';
import { DataStatus, LegalDocument, LegalDocumentType, OnboardingData, Status } from '@ktypes/models';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LegalDocumentsService {
  constructor(
    private _authDataService: AuthDataService,
    private _browserStorage: BrowserStorage,
    private _groupValidationBloc: GroupValidationBloc,
    private _legalDocumentsBloc: LegalDocumentsBloc
  ) {}

  async fetchLegalDocuments(groupIdInput?: string, docType?: LegalDocumentType) {
    const groupId = await this.getGroupId(groupIdInput);

    if (!groupId) {
      this._legalDocumentsBloc.setLegalDocumentsError(new DataStatus<LegalDocument>(Status.error), docType);
      return;
    }

    void this._legalDocumentsBloc.fetchLegalDocuments(groupId, docType);
  }

  async getGroupId(groupIdInput?: string): Promise<string | false> {
    const onboardingData: OnboardingData = this._browserStorage.getObject('onboarding') as OnboardingData;
    const userGroupCode: string = this._authDataService.loadToken()?.user?.group?.groupCode;
    const groupValidationInfo = this._groupValidationBloc.checkForGroup();

    if (groupValidationInfo.error && !groupIdInput && !userGroupCode) {
      return false;
    }

    // use, in order, groupId passed in, from groupValidationInfo, onboardingData
    let groupId = groupIdInput || groupValidationInfo.groupId || onboardingData?.groupId;

    if (!groupId && userGroupCode) {
      this._groupValidationBloc.refreshGroup(userGroupCode);
      let subscription: Subscription;
      groupId = await new Promise((resolve, reject) => {
        subscription = this._groupValidationBloc.groupValidationDataStatus$.subscribe((status) => {
          if (status && status.status === Status.done && status?.data?.id != null) {
            resolve(status?.data?.id);
          } else if (status && status.status === Status.error) {
            reject(null);
          }
        });
      });
      if (subscription) {
        subscription.unsubscribe();
      }
    }

    return groupId;
  }
}
